.cp {
    padding: 1rem;

    &__input {
        height: 2rem;
        width: 100%;
        margin-bottom: 1rem;
        padding-left: 0.5rem;
    }

    &__file-input {
        margin-bottom: 1rem;
    }

    &__heading {
        margin-bottom: 2rem;
    }

    &__quill {
        margin-bottom: 1rem;
    }

    &__cta {
        font-size: 1.3rem;
        padding: 0.5rem;
        width: fit-content;
        color: #FF0000;
        background-color: white;
        border: 2px solid #FF0000;
        transition: 0.2s;
        cursor: pointer;
        margin-bottom: 1rem;
        width: 100%;
    }

    &__cta:hover {
        background-image: linear-gradient(to bottom, #FF3333, #FF0000);
        color: white;
    }
}

.ql-editor {
    min-height: 10rem;
}