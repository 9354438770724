@use '../../assets/styles/global.scss' as *;

.header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 3rem;
    z-index: 999;
    
    @include desktop {
        background-color: #111111;
        height: 4rem;
        top: 0;
        padding: 0 2rem;
        border-bottom: 2px solid white;
        transition: 0.5s;
    }

    &__section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        width: 100%;
        @include desktop {
            max-width: 1200px;
        }

    }

    &__logo {
        display: none;
        cursor: pointer;
        @include desktop {
            display: block;
            height: calc(4rem - 2px);
            padding: 0.5rem 0;
        }
    }

    &__cta {
        display: none;
    }

    &__list {
        list-style: none;
        display: none;
        @include desktop {
            display: flex;
            align-items: center;
        }
    }

    &__icon {
        height: 61px;
        width: 61px;
        font-size: 3.5rem;
        position: absolute;
        bottom: 2rem;
        right: 2rem;
        cursor: pointer;
        padding: 0.5rem;
        border: 2px solid white;
        transition: 0.2s;
        
    }

    &__icon:hover {
        background-color: white;
        color: black;
    }

    &__icon--mobile {
        display: block;
        border: none;
        background-color: transparent;
        font-size: 2.5rem;
        display: flex;
        align-items: center;
        border: 2px solid white;
        background-color: black;
        @include desktop {
            display: none;
        }
    }

    &__options {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        transform: translateY(100dvh);
        transition: 0.2s;
    }

    &__list--mobile {
        display: block;
        position: absolute;
        bottom: 0;
    }

    &__options--open {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        background-color: #111;
    }

    &__li--mobile {
        margin-left: 2rem;
        font-size: 1.6rem;
        margin-bottom: 2rem;
        padding: 0.5rem 1rem;
        border: 2px solid white;
        text-align: center;
        cursor: pointer;
        transition: 0.2s;
    }

    &__li--mobile:hover {
        background-color: white;
        color: black;
    }

    &__li--cta {
        text-align: center;
        margin-left: 2rem;
        font-size: 1.6rem;
        color: $main;
        background-color: #111;
        border: 2px solid $main;
        margin-bottom: 2rem;
        padding: 0.5rem 1rem;
        transition: 0.2s;
        cursor: pointer;
        @include desktop {
            border: none;
            padding: 0;
            margin: 0;
        }
    }

    &__li--cta:hover {
        background-color: $mainhover;
        color: $mainhover !important;
        @include desktop {
            background-color: transparent;
        }
    }

    &__li {
        font-family: 'Rich', sans-serif;
        @include tablet {
            margin-left: 1rem;
            font-size: 1rem;
            cursor: pointer;
            transition: 0.2s;
        }
        @include desktop {
            font-size: 1.2rem;
            margin-left: 2rem;
        } 
    }

    &__li:hover {
        @include desktop {
            color: lightgray;
        }
       
    }
}